'use client';
import { App, Layout } from 'antd';
import clsx from 'clsx';
import React, { memo, useEffect, useState } from 'react';

import Container from '@/components/atoms/Container';
import Footer from '@/components/atoms/Footer';
import Header, { HeaderProps } from '@/components/atoms/Header';
import { useAuth } from '@/lib/hooks/useAuth';
import AuthHeader from '../atoms/Header/AuthHeader';
import { useScroll } from 'framer-motion';
import { usePathname } from 'next/navigation';

const { Content } = Layout;

const MainLayout = ({
  className,
  contentClassName,
  contentFullWidth,
  footerClassName,
  headers,
  children,
  hideFooter,
}: MainLayoutProps) => {
  const { scrollY } = useScroll();
  const [positionY, setPositionY] = useState(0);
  const { isLoggedIn, loading } = useAuth();
  const pathname = usePathname();

  useEffect(() => {
    const updatePositionY = (latest: number) => {
      setPositionY(latest);
    };
    const unsubY = scrollY.on('change', updatePositionY);
    return () => {
      unsubY();
    };
  }, [scrollY]);

  return (
    <App>
      <Layout
        className={clsx('layout overflow-x-hidden', {
          [`${className}`]: !!className,
        })}
      >
        {isLoggedIn ? (
          <AuthHeader scrollY={positionY} />
        ) : (
          <Header scrollY={positionY} {...headers} />
        )}
        <Container fullWidth={contentFullWidth} className="grow">
          <Content
            className={clsx({
              [`${contentClassName}`]: !!contentClassName,
              'mt-[99px] lg:mt-[4.5rem]':
                pathname !== '/' && (!isLoggedIn || loading),
              'lg:mt-[138.5px]': pathname === '/' && !isLoggedIn,
              'mt-[115px] lg:mt-[4.5rem]': pathname !== '/' && isLoggedIn,
              'mt-[4rem] lg:mt-[138.5px]':
                ['/', '/messages'].includes(pathname) && isLoggedIn,
            })}
          >
            {children}
          </Content>
        </Container>
        {!hideFooter && <Footer className={footerClassName} />}
      </Layout>
    </App>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  footerClassName?: string;
  headers?: HeaderProps;
  hideFooter?: boolean;
  contentFullWidth?: boolean;
};

export default memo(MainLayout);
