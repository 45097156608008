import { Input, InputProps } from 'antd';
import { memo } from 'react';

import clsx from 'clsx';
import SearchIcon from 'public/assets/icons/icon-search.svg';

const SearchInput = ({
  size,
  className,
  iconClassName,
  ...props
}: SearchInputProps) => {
  return (
    <Input
      className={clsx({
        'pl-5 px-3 pr-4': size === 'large',
        '!px-3 !p-0 h-[42px]': size === 'middle',
        [`${className}`]: !!className,
      })}
      prefix={
        <SearchIcon
          className={clsx('mr-4', { [`${iconClassName}`]: !!iconClassName })}
        />
      }
      {...props}
    />
  );
};

type SearchInputProps = InputProps & {
  iconClassName?: string;
};

export default memo(SearchInput);
