import { usePathname } from 'next/navigation';

import { useRoom } from '@/lib/hooks/useRoom';

import MenuItem from './MenuItem';

const Message = () => {
  const pathname = usePathname();

  const { unreadRoomCount } = useRoom();

  return (
    <MenuItem
      icon="messages"
      name="แชท"
      badge={unreadRoomCount}
      to="/messages"
      selected={pathname === '/messages'}
    />
  );
};

export default Message;
