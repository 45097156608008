'use client';
import { truncate } from '@/utils/functions/addons';
import { Badge, List, ListProps } from 'antd';
import clsx from 'clsx';
import { memo } from 'react';

const NotificaitonList = ({ onClick, mini, ...props }: NotificationProps) => {
  return (
    <List
      {...props}
      renderItem={(item: any) => (
        <List.Item
          key={item.id}
          className={clsx('cursor-pointer', {
            'bg-blue-50': !item.isRead && !mini,
            'hover:bg-blue-100': mini || !item.isRead,
            'hover:bg-neutral-50': item.isRead,
            '!p-5': !mini,
            '!p-2': mini,
          })}
          onClick={() => onClick(item)}
        >
          <List.Item.Meta
            title={
              <div
                title={item.title}
                className={clsx('flex gap-x-2', {
                  'text-sm font-medium': mini && !item?.isRead,
                  'text-sm font-light text-gray-400': mini && item?.isRead,
                  'text-base font-medium': !mini,
                })}
              >
                {mini && !item.isRead && <Badge color="#0278D6" />}{' '}
                {mini ? truncate(item.title, 50) : item.title ?? '-'}
              </div>
            }
            description={
              !mini && item?.description ? (
                <span>{item?.description}</span>
              ) : null
            }
          />
        </List.Item>
      )}
    />
  );
};

type NotificationProps = ListProps<any> & {
  onClick: (item: any) => void;
  mini?: boolean;
};

export default memo(NotificaitonList);
