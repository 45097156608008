import Image from 'next/image';
import { memo } from 'react';

import ChevronDownIcon from 'public/assets/icons/icon-down.svg';

import clsx from 'clsx';
import styles from '../index.module.scss';

const MenuIcon = ({ badge = 0, icon, name, dropdown }: MenuIconProps) => {
  return (
    <div className="menu-icon flex flex-col items-center">
      <div className="relative">
        {badge > 0 && (
          <div className={clsx(styles.badge, 'top-0 right-[-6px]')}>
            {badge}
          </div>
        )}

        <Image
          src={`/assets/icons/icon-${icon}.svg`}
          width={28}
          height={28}
          alt={icon}
        />
      </div>

      <div className="flex-center gap-[6px]">
        <span className="text-xs">{name}</span>
        {dropdown && <ChevronDownIcon />}
      </div>
    </div>
  );
};

type MenuIconProps = {
  icon: string;
  name: string;
  badge?: number;
  dropdown?: boolean;
};

export default memo(MenuIcon);
