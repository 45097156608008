import { Avatar, Divider, Dropdown } from 'antd';
import Image from 'next/image';
import { memo, useState } from 'react';
import styles from '../index.module.scss';
import MenuItem from './MenuItem';

import { truncate } from '@/utils/functions/addons';
import { UserOutlined } from '@ant-design/icons';
import { useRouter } from 'next/navigation';
import EyeIcon from 'public/assets/icons/icon-eye.svg';
import SettingIcon from 'public/assets/icons/icon-setting.svg';
import UserIcon from 'public/assets/icons/icon-user.svg';

const MyProfile = ({ user, logout, isBusiness }: MyProfileProps) => {
  const [open, setOpen] = useState(false);

  const { push } = useRouter();

  const onClick = (to: string) => {
    push(to);
    setOpen(false);
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
  };

  return (
    <Dropdown
      getPopupContainer={() => document.getElementById('header') as any}
      className={styles.dropdown}
      trigger={['click']}
      placement="bottomRight"
      destroyPopupOnHide
      open={open}
      onOpenChange={onOpenChange}
      menu={{
        items: [
          {
            key: '1',
            type: 'group',
            label: 'บัญชีของฉัน',
            children: [],
          },
        ],
      }}
      dropdownRender={(menu) => (
        <div className={styles.cardProfile}>
          <div className="header">
            <span className="text-slate-400 text-xs">บัญชีของฉัน</span>
            <div className="rounded-xl p-2 flex gap-2 border border-neutral-200 mt-4">
              <Avatar
                size={40}
                icon={<UserOutlined />}
                src={user?.avatar?.url}
              />
              <div className="flex flex-col">
                <span
                  className="name text-dark"
                  title={`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
                >
                  {truncate(user?.firstName, 12)} {truncate(user?.lastName, 5)}{' '}
                </span>
                <span className="email text-primary text-xs">
                  {user?.email}
                </span>
              </div>
            </div>
          </div>

          <div className="setting mt-6 flex flex-col">
            <p className="text-slate-400 text-xs mb-[10px]">
              จัดการบัญชีของฉัน
            </p>

            <div className="menu flex flex-col gap-y-2">
              <div
                className="flex gap-2 items-center cursor-pointer p-2 hover:bg-blue-50 rounded-md"
                onClick={() => onClick(`/profile/${user?.id}`)}
              >
                <EyeIcon className="[&>g]:stroke-gray-400" />
                <span>โปรไฟล์สาธารณะของฉัน</span>
              </div>

              <div
                className="flex gap-2 items-center cursor-pointer p-2 hover:bg-blue-50 rounded-md"
                onClick={() => onClick(`/my-account`)}
              >
                <UserIcon className="[&>g]:stroke-gray-400" />
                <span>จัดการโปรไฟล์ของฉัน</span>
              </div>

              {isBusiness && (
                <div
                  className="flex gap-2 items-center cursor-pointer p-2 hover:bg-blue-50 rounded-md"
                  onClick={() => onClick(`/dashboard`)}
                >
                  <Image
                    src={`/assets/icons/icon-global.svg`}
                    width={20}
                    height={20}
                    alt={'global-icon'}
                  />
                  <span>การเข้าถึงโฆษณา</span>
                </div>
              )}

              <div
                className="flex gap-2 items-center cursor-pointer p-2 hover:bg-blue-50 rounded-md"
                onClick={() => onClick(`/settings`)}
              >
                <SettingIcon />
                <span>ตั้งค่า</span>
              </div>
            </div>
          </div>
          <Divider className="m-0 p-0 my-4" />

          <div
            className="flex gap-2 items-center cursor-pointer p-2 hover:bg-blue-50 rounded-md"
            onClick={() => logout()}
          >
            <span>ออกจากระบบ</span>
          </div>
        </div>
      )}
    >
      <MenuItem icon="profile" name="โปรไฟล์" dropdown />
    </Dropdown>
  );
};

type MyProfileProps = {
  user: any;
  logout: () => void;
  isBusiness: boolean;
};

export default memo(MyProfile);
