export const truncate = (value: string, length = 10) => {
  if (!value) return '';
  if (value.length > length) return `${value.slice(0, length)}...`;
  return value;
};

export const chunkArray = (arr: any[], chunkSize: number): any[][] => {
  const result: any[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
};

export const combineCompany = (sponsors: any[], list: any[]): any[] => {
  const SPLIT = 8;
  const result = [];
  let specialIndex = 0;
  let normalIndex = 0;

  while (normalIndex < list.length) {
    if (
      (result.length % SPLIT === 0 || result.length % SPLIT === 1) &&
      sponsors[specialIndex]
    ) {
      result.push(sponsors[specialIndex]);
      specialIndex++;
    } else if (list[normalIndex]) {
      result.push(list[normalIndex]);
      normalIndex++;
    }
  }
  return result;
};

export const hasValue = (obj: object): boolean => {
  if (Object.keys(obj).length === 0) return false;
  return Object.values(obj).some((item) => !!item);
};

export const querystring = (params: any) => {
  return new URLSearchParams(JSON.parse(JSON.stringify(params))).toString();
};

export const formatPrice = (value: number | string): string => {
  const number = `${value}`;
  const price = number.indexOf('.');
  return number.replace(
    /\d(?=(?:\d{3})+(?:\.|$))/g,
    (num: string, index: number) =>
      price < 0 || index < price ? `${num},` : num,
  );
};

export const scrollToContent = () => {
  const element = document.getElementById('top-content');
  if (element) {
    element.scrollIntoView();
  }
};
