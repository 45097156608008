import Image from 'next/image';
import Link from 'next/link';
import { memo, useCallback, useState } from 'react';

import Container from '@/components/atoms/Container';
import { Modal } from 'antd';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';

const SOCIAL: any[] = [
  {
    name: 'facebook',
    link: 'https://www.facebook.com/profile.php?id=100090149922767',
  },
  { name: 'line', link: 'https://lin.ee/7Ezx3Lr' },
  { name: 'instagram', link: 'https://www.instagram.com/medimatch.app' },
  { name: 'tiktok', link: 'https://www.tiktok.com/@medimatch.app' },
];

const Footer = ({ className }: FooterProps) => {
  const pathName = usePathname();
  const [modalContact, setModalContact] = useState<boolean>(false);

  const onOpenModalContact = useCallback(() => {
    setModalContact(true);
  }, []);

  const onCloseModalContact = useCallback(() => {
    setModalContact(false);
  }, []);

  return (
    <Container>
      <footer
        className={clsx('mx-7 my-10 xl:mx-25', {
          [`${className}`]: !!className,
          '!my-[75px]': pathName.includes('/jobs'),
        })}
      >
        <div className="flex flex-col items-center py-9 rounded-large bg-gray-200 gap-4 xl:py-12 xl:px-10 xl:flex-row">
          <span className="text-sm xs:text-base text-gray-400 xl:mr-auto order-2 xl:order-1">
            © {new Date().getFullYear()} - Medimatch. All rights reserved.
          </span>
          <div className="flex flex-col items-center gap-y-4 order-1 xl:order-2 xl:flex-row">
            <div className="flex flex-col gap-x-2 items-center order-2 xl:flex-row xl:mr-10 xl:gap-x-10 xl:order-2">
              <span
                className="text-gray-400 hover:text-gray-500 cursor-pointer"
                onClick={() => onOpenModalContact()}
              >
                ติดต่อเรา
              </span>
              <Link
                className="text-gray-400 hover:text-gray-500"
                href="/privacy-and-policy?id=legal"
                scroll={false}
              >
                ข้อปฏิบัติและกฎหมาย
              </Link>
              <Link
                className="text-gray-400 hover:text-gray-500"
                href="/privacy-and-policy?id=policy"
                scroll={false}
              >
                นโยบายความเป็นส่วนตัว
              </Link>
            </div>
            <div className="social flex gap-x-6 order-1 xl:order-3">
              {SOCIAL.map((item, index: number) => {
                return (
                  <Link
                    key={index}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      className="cursor-pointer hover:brightness-75"
                      src={`/assets/icons/icon-${item.name}.svg`}
                      alt={item.name}
                      width={32}
                      height={32}
                    />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </footer>

      {modalContact && (
        <Modal
          title="ติดต่อเรา"
          open={modalContact}
          onOk={onOpenModalContact}
          onCancel={onCloseModalContact}
          width={382}
          footer={null}
          centered
        >
          <p>บริษัท เมดวิลล์ จำกัด</p>
          <p className="mt-2">
            เลขที่ 9/26 หมู่ที่ 2 แขวงทวีวัฒนา เขตทวีวัฒนา กรุงเทพมหานคร 10170
          </p>
          <div className="contact my-3 flex flex-col gap-3">
            <div className="flex items-center gap-x-2">
              <Image
                src={`/assets/icons/icon-phone.svg`}
                alt={'phone'}
                width={16}
                height={16}
              />
              <a
                className="text-dark hover:text-gray-500"
                href="tel:0814994266"
              >
                081-499-4266
              </a>
            </div>

            <div className="flex items-center gap-x-2">
              <Image
                src={`/assets/icons/icon-email.svg`}
                alt={'email'}
                width={16}
                height={16}
              />
              <a
                className="text-dark hover:text-gray-500"
                href="mailto:info@medimatch.app"
              >
                info@medimatch.app
              </a>
            </div>

            <div className="flex items-center gap-x-2">
              <Image
                src={`/assets/icons/icon-line.svg`}
                alt={'line'}
                width={16}
                height={16}
              />
              <a
                className="text-dark hover:text-gray-500"
                href="https://lin.ee/7Ezx3Lr"
                target="_blank"
                rel="noreferrer"
              >
                @medimatch
              </a>
            </div>
          </div>
        </Modal>
      )}
    </Container>
  );
};

type FooterProps = {
  className?: string;
};

export default memo(Footer);
