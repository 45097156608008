'use client';
import { Divider, Form } from 'antd';
import clsx from 'clsx';
import { useQueryState } from 'next-usequerystate';
import { usePathname, useRouter } from 'next/navigation';
import { memo, useCallback } from 'react';

import { Desktop, TabletAndBelow } from '@/components/shared/Responsive';
import { useAuth } from '@/lib/hooks/useAuth';
import { AUTH_MENUS } from '@/utils/constants/menu';

import MobileDrawer from './MobileDrawer';

import AddCircleIcon from 'public/assets/icons/icon-add-circle.svg';
import Button from '../Button';
import SearchInput from '../SearchInput';
import MenuItem from './components/MenuItem';
import MyProfile from './components/MyProfile';
import Notification from './components/Notification';
import Message from './components/Message';
import styles from './index.module.scss';
import Navigator from '../Navigator';

const AuthHeader = ({ className, scrollY }: AuthHeaderProps) => {
  const { user, role, logout, isBusiness } = useAuth();
  const [form] = Form.useForm();
  const pathname = usePathname();
  const { push } = useRouter();
  const [keyword, setKeyword] = useQueryState('keyword');

  const hasAuth = useCallback(
    (auth: string[]) => {
      if (auth.includes('ALL')) return true;
      return auth.includes(role);
    },
    [role],
  );

  const onSearch = (values: any) => {
    const q = (values.keyword || '').trim();
    if (!q) return;
    form.resetFields();
    if (pathname === '/jobs') {
      return setKeyword(`${q}`);
    }
    push(`/jobs?keyword=${q}`);
  };

  return (
    <>
      <header
        id="header"
        className={clsx(styles.authHeader, { [`${className}`]: !!className })}
      >
        <nav className={styles.authNavbar}>
          <Desktop>
            <MenuItem
              icon="home"
              name="หน้าหลัก"
              to="/"
              selected={pathname === '/'}
            />

            {/* Search Header */}
            <Form
              name="search-header"
              form={form}
              onFinish={onSearch}
              autoComplete="off"
              className="!hidden xl:!flex search-header items-center gap-3 ml-10"
            >
              <Form.Item className="mb-0" name="keyword">
                <SearchInput
                  className="bg-neutral-50 !rounded-xl hover:bg-neutral-50 w-[295px]"
                  placeholder="ค้นหาตำแหน่ง, ชื่อบริษัท หรืออื่น ๆ"
                  bordered={false}
                  size="middle"
                  maxLength={100}
                />
              </Form.Item>
              <Button htmlType="submit" ghost>
                ค้นหา
              </Button>
            </Form>

            <div className="menu ml-auto flex-center">
              {AUTH_MENUS.map((menu, index: number) => {
                return (
                  hasAuth(menu.auth) &&
                  !menu.showMobile && (
                    <MenuItem
                      key={index}
                      icon={menu.icon}
                      name={menu.name}
                      to={menu.to}
                      selected={pathname === menu.to}
                    />
                  )
                );
              })}
              <Message />
              <Notification />

              <Divider className="m-[10px] !h-10" type="vertical" />
              <MyProfile user={user} logout={logout} isBusiness={isBusiness} />

              {isBusiness && (
                <div className="menu-button ml-5 flex items-center gap-x-4">
                  <Button
                    type="primary"
                    icon={<AddCircleIcon className="[&>g]:stroke-white mr-1" />}
                    onClick={() => push(`/jobs/create`)}
                  >
                    ลงประกาศงาน
                  </Button>
                  <Button
                    onClick={() =>
                      push(`/messages?room=${user?.admin_room?.id}`)
                    }
                    ghost
                  >
                    ติดต่อโฆษณา
                  </Button>
                </div>
              )}
            </div>
          </Desktop>

          <MobileDrawer scrollY={scrollY} menus={AUTH_MENUS} />
        </nav>
        <Navigator />
      </header>
    </>
  );
};

type AuthHeaderProps = {
  className?: string;
  rounded?: boolean;
  shadow?: 'primary' | 'secondary';
  scrollY?: number;
};

export default memo(AuthHeader);
