import { Dropdown } from 'antd';
import { memo, useMemo, useState } from 'react';

import NotificationList from '@/components/shared/NotificationList';
import { useNotification } from '@/lib/hooks/useNotification';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { useRouter } from 'next/navigation';
import styles from '../index.module.scss';
import MenuItem from './MenuItem';
import clsx from 'clsx';

const Notification = () => {
  const { push } = useRouter();
  const [open, setOpen] = useState(false);

  const {
    data,
    loading,
    countUnRead,
    onClick: onClickNotification,
    onReadAll,
    loadingReadAll,
  } = useNotification({
    limit: 5,
    enabled: open === true,
  });

  const onClick = (to: string) => {
    push(to);
    setOpen(false);
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
  };

  const onClickNotiMessage = (item: any) => {
    onClickNotification(item);
    setOpen(false);
  };

  const onMaskReadAll = () => {
    onReadAll();
  };

  const isReadedAll = useMemo(() => {
    return data?.filter((noti) => !noti?.isRead).length === 0;
  }, [data]);

  return (
    <Dropdown
      className={styles.dropdown}
      trigger={['click']}
      placement="bottomRight"
      open={open}
      onOpenChange={onOpenChange}
      getPopupContainer={() => document.getElementById('header') as any}
      menu={{
        items: [
          {
            key: '1',
            type: 'group',
            label: '',
          },
        ],
      }}
      dropdownRender={(menu) => (
        <div className={styles.cardNotification}>
          <div className="header p-1 flex items-center">
            <span className="!text-dark text-base font-medium">
              การแจ้งเตือน
            </span>
            <span
              className={clsx(
                'text-primary text-xs font-medium ml-auto cursor-pointer hover:!text-blue-400',
                {
                  '!text-gray-400 hover:!text-gray-400 !cursor-not-allowed':
                    isReadedAll,
                },
              )}
              onClick={isReadedAll ? undefined : () => onMaskReadAll()}
            >
              {loadingReadAll ? <LoadingOutlined /> : <CheckOutlined />}{' '}
              ทำเครื่องหมายทั้งหมดว่าอ่านแล้ว
            </span>
          </div>

          <NotificationList
            loading={loading}
            itemLayout="horizontal"
            dataSource={data}
            locale={{ emptyText: 'ไม่มีการแจ้งเตือน' }}
            onClick={onClickNotiMessage}
            mini
          />

          <div
            onClick={() => onClick(`/notifications`)}
            className="flex gap-2 justify-center items-center cursor-pointer p-2 hover:bg-blue-50 rounded-md text-center"
          >
            <span className="text-sm font-medium">ดูทั้งหมด</span>
          </div>
        </div>
      )}
    >
      <MenuItem
        icon="notification"
        name="แจ้งเตือน"
        dropdown
        badge={countUnRead}
      />
    </Dropdown>
  );
};

export default memo(Notification);
