import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import { memo } from 'react';
import MenuIcon from './MenuIcon';

import styles from '../index.module.scss';

const MenuItem = ({
  name,
  icon,
  to,
  badge,
  dropdown,
  selected,
  ...props
}: MenuItemProps) => {
  const { push } = useRouter();

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    if (to) {
      push(to);
    }
  };

  return (
    <div
      className={styles.menuItem}
      onClick={(event) => onClick(event)}
      {...props}
    >
      <MenuIcon name={name} icon={icon} badge={badge} dropdown={dropdown} />
      <div
        className={clsx(styles.menuIndicator, {
          hidden: !selected,
        })}
      />
    </div>
  );
};

type MenuItemProps = {
  icon: string;
  name: string;
  to?: string;
  badge?: number;
  dropdown?: boolean;
  selected?: boolean;
};

export default memo(MenuItem);
